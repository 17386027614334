import "./NewRow.scss"
import ContainerMain from "src/pages/common/ContainerMain"
import ImageZoomEffect from "pages/common/ImageZoomEffect"

export default function NewRow({
  image,
  title,
  more,
  description,
  position = "left",
}) {
  return (
    <>
      <div className="qaawmpstdv">
        <ContainerMain>
          <div
            className={`oflhydofzh ${
              position === "left" ? "left-content" : "right-content"
            }`}
          >
            {position === "left" ? (
              <div className="vnlquwrayu">
                <ImageZoomEffect src={image} alt="" className="udncvqnrno" />
              </div>
            ) : null}
            <div
              className={`cfrrviuodn ${
                position === "left" ? "tuqbrotgqq" : "nxhztlreor"
              }`}
            >
              <div className="frrtcscsui">{title}</div>
              <div className="khhzvvyzvx">{more}</div>
              <div className="encrxneqoq">{description}</div>
            </div>
            {position === "right" ? (
              <div className="vnlquwrayu">
                <ImageZoomEffect src={image} alt="" className="udncvqnrno" />
              </div>
            ) : null}
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
